<template>
  <div class="likes">
    <v-card min-height="500" flat>
      <v-card-text>
        <BoardLinks
          no-products
          :no-incoming-orders="!can_view_incoming_orders"
        ></BoardLinks>
        <v-row dense align="stretch">
          <v-col cols="12" md="9" sm="12" xs="12">
            <v-card
              class="fill-height border-2 border-color--grey fullwidth"
              flat
              min-height="500"
            >
              <v-card-title class="primary--text fw-700">
                My Products
              </v-card-title>
              <v-card-text>
                <v-sheet v-if="my_products.length" class="fullwidth">
                  <v-row dense>
                    <v-col
                      cols="12"
                      md="3"
                      v-for="product in my_products"
                      :key="product.id"
                      sm="12"
                    >
                      <ProductCard
                        class="mx-auto"
                        :product="product"
                        @viewProduct="viewProduct(product)"
                        @addToLikes="handleLiking(product)"
                        @deleteProduct="handleDelete(product)"
                        @editProduct="editProduct(product)"
                        @shareProduct="shareProduct(product)"
                      ></ProductCard>
                    </v-col>
                    <v-col cols="12" md="3" sm="12" v-if="canAddProduct">
                      <v-card
                        @click="goToCreate"
                        elevation="3"
                        class="fill-height rounded-10 d-flex flex-column align-center justify-center"
                      >
                        <v-icon color="primary" size="80">mdi-plus</v-icon>
                        <strong class="primary--text">Create New</strong>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-sheet>
                <Empty
                  v-else-if="!my_products.length"
                  headline="You don't have a product yet!"
                  min-height="90vh"
                ></Empty>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" sm="12" xs="12">
            <v-card
              tile
              flat
              class="fill-height rounded-5 border-2 border-color--grey pa-1"
            >
              <v-list dense flat>
                <v-subheader class="fs-20 fw-900 primary--text">
                  Notifications
                </v-subheader>
                <v-list-item-group v-if="notifications.length">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Sample notification
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <Empty
                  min-height="90vh"
                  v-else
                  headline="No notification yet!"
                ></Empty>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { offers_mixin } from '../mixin'
import request from '@/services/axios_instance'
import ProductCard from '../components/ProductCard.vue'
import BoardLinks from '../components/BoardLinks.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    ProductCard,
    BoardLinks
  },
  mixins: [offers_mixin],
  data: () => ({
    my_products: [],
    my_products_next_page_url: null,
    loading_my_products: false,
    notifications: [],
    assigned_members: []
  }),
  created() {
    this.getMyProducts() 
  },
  computed:{ 
    ...mapGetters(['user']) 
  },
  methods: { 
    goToCreate() {
      this.$router.push({
        name: 'product_builder'
      })
    },
    getMyProducts() {
      this.loading_my_products = true
      request
        .get(`api/products/mine`)
        .then(({ data }) => {
          this.my_products = data.data
          this.my_products_next_page_url = data.next_page_url
        })
        .finally(() => (this.loading_my_products = false))
    },
    handleLiking(product) {
      this.likeUnlikeProduct(product, (data) => {
        let index = this.my_products.findIndex((i) => i.id == data.id)
        if (~index) {
          this.my_products[index].likes_count = data.likes_count
          this.my_products[index].is_liked = data.is_liked
        }
      })
    },
    handleDelete(product) {
      this.confirmDeleteProduct(product, (data) => {
        let index = this.my_products.findIndex((i) => i.id == data.id)
        if (~index) {
          this.my_products.splice(index, 1)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
